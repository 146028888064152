@font-face {
font-family: '__apparat_8fb712';
src: url(/_next/static/media/da16459c557996cc-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 300;
font-style: normal;
}

@font-face {
font-family: '__apparat_8fb712';
src: url(/_next/static/media/24cc23635ec3bc81-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 300;
font-style: italic;
}

@font-face {
font-family: '__apparat_8fb712';
src: url(/_next/static/media/b799e27132f64518-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__apparat_8fb712';
src: url(/_next/static/media/d4afbff416c3667c-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: italic;
}

@font-face {
font-family: '__apparat_8fb712';
src: url(/_next/static/media/79ab129083b631ca-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__apparat_8fb712';
src: url(/_next/static/media/dfcea7cf41522092-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: italic;
}

@font-face {
font-family: '__apparat_8fb712';
src: url(/_next/static/media/9342641337853301-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 600;
font-style: normal;
}

@font-face {
font-family: '__apparat_8fb712';
src: url(/_next/static/media/80fbe12dcbc86352-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: '__apparat_8fb712';
src: url(/_next/static/media/74e155e2d3c37316-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: italic;
}@font-face {font-family: '__apparat_Fallback_8fb712';src: local("Arial");ascent-override: 74.65%;descent-override: 23.57%;line-gap-override: 19.64%;size-adjust: 101.81%
}.__className_8fb712 {font-family: '__apparat_8fb712', '__apparat_Fallback_8fb712'
}.__variable_8fb712 {--font-apparat: '__apparat_8fb712', '__apparat_Fallback_8fb712'
}

@font-face {
font-family: '__zona_78b9a9';
src: url(/_next/static/media/61ce94f2c7adec0d-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__zona_78b9a9';
src: url(/_next/static/media/3d9bd594c24fced5-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: normal;
}@font-face {font-family: '__zona_Fallback_78b9a9';src: local("Arial");ascent-override: 87.77%;descent-override: 15.36%;line-gap-override: 0.00%;size-adjust: 113.93%
}.__className_78b9a9 {font-family: '__zona_78b9a9', '__zona_Fallback_78b9a9'
}.__variable_78b9a9 {--font-zona: '__zona_78b9a9', '__zona_Fallback_78b9a9'
}

@font-face {
font-family: '__adelle_f98516';
src: url(/_next/static/media/49e9923c24b641d0-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__adelle_f98516';
src: url(/_next/static/media/9f8fc2aacc341c68-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: normal;
}@font-face {font-family: '__adelle_Fallback_f98516';src: local("Arial");ascent-override: 95.77%;descent-override: 29.41%;line-gap-override: 0.00%;size-adjust: 106.09%
}.__className_f98516 {font-family: '__adelle_f98516', '__adelle_Fallback_f98516'
}.__variable_f98516 {--font-adelle: '__adelle_f98516', '__adelle_Fallback_f98516'
}

